import React, { useEffect, useState, useContext } from "react";
import API from "../API";
import { ShowContext } from "../App";
import { Col, Container, Row, Form, Table } from "react-bootstrap";
import FormModal from "../Components/FormModal";

let myInitialValues = {
  email: "",
};

const Query = () => {
  const { setShow, setMsg } = useContext(ShowContext);
  const [open, setOpen] = useState(false);
  const [list, setList] = useState([]);
  const [data, setData] = useState(myInitialValues);
  useEffect(() => {
    getList(setList);
  }, []);
  return (
    <>
      <FormModal
        show={open}
        handleClose={() => {
          setData(myInitialValues);
          setOpen(false);
        }}
        handleSubmit={async () => {
          if (!data.email) {
            alert("Incomplete Data");
          } else {
            await addNew(data, list, setList, setShow, setMsg);
            getList(setList);
            setOpen(false);
          }
        }}
        heading={"Add New Notice"}
      >
        <Form>
          <Row className="mb-3">
            <Form.Group as={Col}>
              <Form.Label>Notice No.</Form.Label>
              <Form.Control
                type="email"
                name="email"
                placeholder="email@domain.com"
                value={data.email}
                onChange={(e) => {
                  setData({ ...data, email: e.target.value });
                }}
              />
            </Form.Group>
          </Row>
        </Form>
      </FormModal>
      <Container className="p-3">
        <Row className="mb-3">
          <Col md={9}>
            <h2>Queries/Messages</h2>
          </Col>
          {/* <Col md={3}>
            <Button style={{ width: "100%" }} onClick={() => setOpen(true)}>
              {" "}
              + Add New{" "}
            </Button>
          </Col> */}
        </Row>
        {list && list.length > 0 && (
          <Row>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Messages</th>
                  {/* <th>Status</th> */}
                </tr>
              </thead>
              <tbody>
                {list.map((item, index) => (
                  <tr key={index + 1}>
                    <td>{index + 1}</td>
                    <td>{item.name}</td>
                    <td>{item.email}</td>
                    <td>{item.message}</td>
                    {/* <td>
                      <Form.Check type="switch" checked={item.status == 1} />
                    </td> */}
                  </tr>
                ))}
              </tbody>
            </Table>
          </Row>
        )}
      </Container>
    </>
  );
};

function getList(setList) {
  API.get("/query")
    .then((res) => {
      if (res.data.status === "success") {
        setList(res.data.data);
      }
    })
    .catch((error) => {
      console.log(error);
    });
}

async function addNew(values, list, setList, setShow, setMsg) {
  await API.post("query", values)
    .then((res) => {
      if (res.data.status === "success") {
        // setList([...list, res.data.data]);
        setShow(true);
        setMsg(res.data.message);
      }
    })
    .catch((error) => {
      console.log(error);
    });
}

export default Query;
