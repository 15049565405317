import React, { useContext } from "react";
import { useLocation } from "react-router-dom";
import { UserContext } from "../App";
import {
  MdDashboard,
  MdEditDocument,
  MdMarkEmailRead,
  MdViewCarousel,
  MdTextSnippet,
  MdPerson,
  MdOutlineBrandingWatermark,
  MdOutlineTextsms,
  MdAutoGraph,
  MdOutlineEvent,
  MdCalendarToday,
  MdHelpOutline,
  MdOutlineFormatListBulleted,
} from "react-icons/md";
import { FaFileAlt } from "react-icons/fa";
import { Nav } from "react-bootstrap";
import "./Sidebar.css";

const Sidebar = ({ className }) => {
  let location = useLocation();
  const { currentUser } = useContext(UserContext);

  return currentUser ? (
    <>
      <Nav
        className={` sidebar ${className || ""}`}
        activeKey={location.pathname}
        // onSelect={(selectedKey) => alert(`selected ${selectedKey}`)}
      >
        {[
          { link: "/dashboard", title: "Dashboard" },
          { link: "/application", title: "Applications", icon: <FaFileAlt /> },
          {
            link: "/notice",
            title: "Public Notices",
            icon: <MdEditDocument />,
          },
          {
            link: "/startup-recruitment",
            title: "Startup Recruitment",
            icon: <MdEditDocument />,
          },
          {
            link: "/subscribe",
            // title: "Newsletter Subsriptions",
            title: "Newsletter Emails",
            icon: <MdMarkEmailRead />,
          },
          {
            link: "/query",
            title: "Query Form",
            icon: <MdHelpOutline />,
          },
          {
            link: "/appointment",
            title: "Mentor Appointments",
            icon: <MdCalendarToday />,
          },
          {
            link: "/carousel",
            title: "Carousel",
            icon: <MdViewCarousel />,
          },
          { link: "/content", title: "Content", icon: <MdTextSnippet /> },
          { link: "/member", title: "Members", icon: <MdPerson /> },
          { link: "/events", title: "Events", icon: <MdOutlineEvent /> },
          {
            link: "/past-events",
            title: "Past Events",
            icon: <MdOutlineEvent />,
          },
          {
            link: "/updates",
            title: "Recent Updates",
            icon: <MdOutlineFormatListBulleted />,
          },
          // {
          //   link: "/portfolio",
          //   title: "Portfolio",
          //   icon: <MdOutlineBrandingWatermark />,
          // },
          {
            link: "/mentor",
            title: "Mentors",
            icon: <MdOutlineBrandingWatermark />,
          },
          {
            link: "/startup",
            title: "Startups",
            icon: <MdOutlineBrandingWatermark />,
          },
          {
            link: "/stats",
            title: "Stats",
            icon: <MdAutoGraph />,
          },
          {
            link: "/testimonial",
            title: "Testimonial",
            icon: <MdOutlineTextsms />,
          },
        ].map((item) => (
          <Nav.Item
            className={`sidebar-item ${
              item.link === location.pathname && "sidebar-item-active"
            }`}
          >
            <Nav.Link href={item.link}>
              {item.icon || <MdDashboard />} <span>{item.title}</span>
            </Nav.Link>
          </Nav.Item>
        ))}
      </Nav>
      {/* <div className="left side-menu" id="sidebar">
        <button
          type="button"
          className="button-menu-mobile button-menu-mobile-topbar open-left waves-effect"
          onClick={() => {
            hideShow();
          }}
        >
          <i className="ion-close" />
        </button>
        <div className="left-side-logo d-block d-lg-none">
          <div className="text-center">
            <a href="index.html" className="logo">
              <img
                className="logo_abif"
                src="assets/images/logo.png"
                height={60}
                alt="logo"
              />
            </a>
          </div>
        </div>
        <div className="sidebar-inner slimscrollleft">
          <div id="sidebar-menu">
            <ul className="sidebar_menu">
              <li className="hover_effect">
                <NavLink
                  className="nav-link waves-effect"
                  to={{ pathname: "/dashboard" }}
                >
                  <MdDashboard />
                  <span className="p_logos"> Home</span>
                </NavLink>

                <NavLink
                  className="nav-link waves-effect"
                  to={{ pathname: "/latestUpdates" }}
                >
                  <MdDashboard />
                  <span className="p_logos"> Add Latest Update</span>
                </NavLink>

                <NavLink
                  className="nav-link waves-effect"
                  to={{ pathname: "/importantLinks" }}
                >
                  <MdDashboard />
                  <span className="p_logos"> Add IMP Links</span>
                </NavLink>

                <NavLink
                  className="nav-link waves-effect"
                  to={{ pathname: "/photo" }}
                >
                  <MdDashboard />
                  <span className="p_logos"> Add Photos</span>
                </NavLink>

                <NavLink
                  className="nav-link waves-effect"
                  to={{ pathname: "/eventPhoto" }}
                >
                  <MdDashboard />
                  <span className="p_logos"> Add Event Photos</span>
                </NavLink>

                <NavLink
                  className="nav-link waves-effect"
                  to={{ pathname: "/circular" }}
                >
                  <MdDashboard />
                  <span className="p_logos"> Add Circulars</span>
                </NavLink>

                <NavLink
                  className="nav-link waves-effect"
                  to={{ pathname: "/msbteOfficers" }}
                >
                  <MdDashboard />
                  <span className="p_logos"> Add MSBTE Officers</span>
                </NavLink>

                <NavLink
                  className="nav-link waves-effect"
                  to={{ pathname: "/eventVideo" }}
                >
                  <MdDashboard />
                  <span className="p_logos"> Add Event Videos</span>
                </NavLink>

                <NavLink
                  className="nav-link waves-effect"
                  to={{ pathname: "/governingCouncil" }}
                >
                  <MdDashboard />
                  <span className="p_logos"> Governing Council</span>
                </NavLink>

                <NavLink
                  className="nav-link waves-effect"
                  to={{ pathname: "/governingBoard" }}
                >
                  <MdDashboard />
                  <span className="p_logos"> Governing Board</span>
                </NavLink>

                <NavLink
                  className="nav-link waves-effect"
                  to={{ pathname: "/menu" }}
                >
                  <MdDashboard />
                  <span className="p_logos"> Menu </span>
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </div> */}
    </>
  ) : null;
};

export default Sidebar;
