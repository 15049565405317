import React, { useEffect, useState } from "react";
import API from "../API";
import {
  Col,
  Container,
  Row,
  Form,
  Table,
  Tab,
  Nav,
  Accordion,
  Button,
} from "react-bootstrap";
import { MdToday, MdOpenInNew } from "react-icons/md";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";

const Applications = ({}) => {
  const [incubationList, setIncubationList] = useState([]);
  const [mentorList, setMentorList] = useState([]);
  const [recruitmentList, setRecruitmentList] = useState([]);
  useEffect(() => {
    getList(`incubation`, setIncubationList);
    getList(`mentor`, setMentorList);
    getList(`recruitment`, setRecruitmentList);
  }, []);
  const exportToExcel = (data, list) => {
    const keys = Object.keys(data[0]);
    const dataArray = [keys];
    data.forEach((item) => {
      const row = keys.map((key) => {
        if (
          (list === "recruitment"
            ? key !== "experience" && key !== "education"
            : true) ||
          !item[key]
        ) {
          return item[key];
        } else {
          let tempArray = JSON.parse(item[key]);
          let tempString = "";
          let tempKeys = Object.keys(tempArray[0]);
          tempArray.map((tempItem, tempIndex) => {
            tempString += `${tempIndex + 1}.\n`;
            tempKeys.map((tItem, tIndex) => {
              tempString += `${tItem}: ${tempItem[tItem]} \n`;
            });
          });
          return tempString;
        }
      });
      dataArray.push(row);
    });
    const ws = XLSX.utils.aoa_to_sheet(dataArray);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, "applications.xlsx");
  };
  const handleExportPDF = (data, list) => {
    const doc = new jsPDF();
    const pageHeight = doc.internal.pageSize.height;
    let yPosition = 20;
    let currentPage = 1;
    for (let i = 0; i < data.length; i++) {
      const section = data[i];
      for (const key in section) {
        const value = section[key];
        const textLines = doc.splitTextToSize(key + ": " + value, 180);
        if (yPosition + textLines.length * 10 > pageHeight) {
          doc.addPage();
          currentPage++;
          yPosition = 20;
        }
        doc.text(textLines, 20, yPosition);
        yPosition += textLines.length * 10;
      }
      if (i < data.length - 1) {
        doc.addPage();
        currentPage++;
        yPosition = 20;
      }
    }
    doc.save("applications.pdf");
  };
  return (
    <div className="p-3">
      <Tab.Container defaultActiveKey="incubation">
        <Row className="mb-3">
          <Col sm={9}>
            <h2>Application Forms</h2>
          </Col>
          <Col sm={3}></Col>
        </Row>
        <Row className="mb-3">
          <Col sm={12} className="mb-3 pl-auto">
            <Nav variant="pills">
              <Nav.Item>
                <Nav.Link eventKey="incubation">Incubation</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="mentor">Mentor</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="recruitment">Recruitment</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="download">Dowload</Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <Tab.Content>
              <Tab.Pane eventKey="download">
                <Row className="mb-3 text-center">
                  <Col md={4}>
                    <Button
                      onClick={() =>
                        exportToExcel(recruitmentList, "recruitment")
                      }
                      style={{ width: "100%", height: "100%" }}
                    >
                      Download Recruitment Application List (Excel)
                    </Button>
                  </Col>
                  <Col md={4}>
                    <Button
                      onClick={() =>
                        handleExportPDF(recruitmentList, "recruitment")
                      }
                      style={{ width: "100%", height: "100%" }}
                    >
                      Download Recruitment Application List (PDF)
                    </Button>
                  </Col>
                  {/* <Col md={4}>
                    <Button
                      onClick={() => {}}
                      variant="danger"
                      style={{ width: "100%", height: "100%" }}
                    >
                      Delete Recruitment Application List
                    </Button>
                  </Col> */}
                </Row>
                <Row className="mb-3 text-center">
                  <Col md={4}>
                    <Button
                      onClick={() =>
                        exportToExcel(incubationList, "incubation")
                      }
                      style={{ width: "100%", height: "100%" }}
                    >
                      Download Incubation Application List (Excel)
                    </Button>
                  </Col>
                  <Col md={4}>
                    <Button
                      onClick={() =>
                        handleExportPDF(incubationList, "incubation")
                      }
                      style={{ width: "100%", height: "100%" }}
                    >
                      Download Incubation Application List (PDF)
                    </Button>
                  </Col>
                </Row>
                <Row className="mb-3 text-center">
                  <Col md={4}>
                    <Button
                      onClick={() => exportToExcel(mentorList, "mentor")}
                      style={{ width: "100%", height: "100%" }}
                    >
                      Download Mentor Application List (Excel)
                    </Button>
                  </Col>
                  <Col md={4}>
                    <Button
                      onClick={() => handleExportPDF(mentorList, "mentor")}
                      style={{ width: "100%", height: "100%" }}
                    >
                      Download Mentor Application List (PDF)
                    </Button>
                  </Col>
                </Row>
              </Tab.Pane>
              <Tab.Pane eventKey="incubation">
                <>
                  {incubationList.map((item, index) => (
                    <Accordion className="mb-3">
                      <Accordion.Item eventKey={`incubation/${index}`}>
                        <Accordion.Header>
                          {index + 1}. {item.name} ({item.startup_name})
                        </Accordion.Header>
                        <Accordion.Body>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              marginBottom: "0.3em",
                            }}
                          >
                            <Row className="mb-3">
                              <Col md={4}>
                                <Button
                                  onClick={() =>
                                    handleExportPDF([item], "incubation")
                                  }
                                >
                                  Download Application (PDF)
                                </Button>
                              </Col>
                            </Row>
                            <strong>A. Applicant's Details:</strong>
                            <span>
                              <strong>
                                <MdToday size={18} /> Application Date:{" "}
                              </strong>
                              {new Date(item.created_at).toLocaleDateString()}
                            </span>
                            <span>
                              <strong>Name: </strong>
                              {item.name}
                            </span>
                            <span>
                              <strong>Gender: </strong>
                              {item.gender}
                            </span>
                            <span>
                              <strong>Date of Birth: </strong>
                              {new Date(item.dob).toLocaleDateString()}
                            </span>
                            <span>
                              <strong>Phone: </strong>
                              {item.phone}
                            </span>
                            <span>
                              <strong>Email: </strong>
                              {item.email}/ {item.email_alternate}
                            </span>
                            <span>
                              <strong>Address for correspondance: </strong>
                              {item.address}
                              <br />
                              <b>Post Office/PinCode: </b>
                              {item.address_post_office}/{item.address_pin_code}
                              <br />
                              <b>District, State: </b>
                              {item.address_district}, {item.address_state}
                            </span>
                            <span>
                              <strong>Highest Qualification: </strong>
                              {item.qualification}
                            </span>
                            <span>
                              <strong>
                                Professional experience (in years):{" "}
                              </strong>
                              {item.experience}
                            </span>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              marginBottom: "0.3em",
                            }}
                          >
                            <strong>B. Startup Details:</strong>
                            <span>
                              <strong>Name of Startup: </strong>
                              {item.startup_name}
                            </span>
                            <span>
                              <strong>
                                Have you registered your company?:{" "}
                              </strong>
                              {item.startup_registered}
                            </span>
                            {item.startup_registered_application_date && (
                              <span>
                                <strong>Application Date: </strong>
                                {item.startup_registered_application_date}
                              </span>
                            )}
                            <span>
                              <strong>
                                If yes, what is the legal status of the startup
                                you have formed?:{" "}
                              </strong>
                              {item.startup_type}
                            </span>
                            <span>
                              <strong>Is it DPIIT recognized?: </strong>
                              {item.startup_dpiit_recognized}
                            </span>
                            {item.startup_dpiit_recognized_application_date && (
                              <span>
                                <strong>DPIIT Application Date: </strong>
                                {item.startup_dpiit_recognized_application_date}
                              </span>
                            )}
                            <span>
                              <strong>If yes, your number?: </strong>
                              {item.startup_dpiit_number}
                            </span>
                            <span>
                              <strong>GST Number of your startup?: </strong>
                              {item.startup_gst_number}
                            </span>
                            <span>
                              <strong>
                                If you do not have a registered company, are you
                                willing to register during the incubation
                                period?:{" "}
                              </strong>
                              {item.startup_registration_willingness}
                            </span>
                            <span>
                              <strong>
                                Registration number / CIN (If registered):{" "}
                              </strong>
                              {item.startup_cin}
                            </span>
                            <span>
                              <strong>
                                Registered address of the company:{" "}
                              </strong>
                              {item.startup_registered_address}
                            </span>
                            <span>
                              <strong>Establishment year: </strong>
                              {item.startup_establishment_year}
                            </span>
                            <span>
                              <strong>Website: </strong>
                              {item.startup_website}
                            </span>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              marginBottom: "0.3em",
                            }}
                          >
                            <strong>C. Brief about the Startup/Idea: </strong>
                            <span>
                              <strong>
                                Executive summary / brief overview of your
                                business proposal:{" "}
                              </strong>
                              {item.startup_summary}
                            </span>
                            <span>
                              <strong>
                                Business model of the Startup / Idea:{" "}
                              </strong>
                              {item.startup_business_model}
                            </span>
                            <span>
                              <strong>Motivation behind Startup / Idea:</strong>
                              {item.startup_motivation}
                            </span>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              marginBottom: "0.3em",
                            }}
                          >
                            <strong>D. Technology:</strong>
                            <span>
                              <strong>
                                Which focus area does your product/service
                                belongs to:{" "}
                              </strong>
                              {item.startup_sector}
                            </span>
                            <span>
                              <strong>
                                Where are you in your product development / path
                                to market process?{" "}
                              </strong>
                              {item.startup_stage}
                            </span>
                            <span>
                              <strong>
                                How long will it take for this
                                Idea/innovation/technology is completely
                                developed and ready for the market (Expected in
                                months) ?{" "}
                              </strong>
                              {item.startup_ready_time}
                            </span>
                            <strong>
                              Please explain your technology in brief:
                              Technology Description: Intended product or
                              service (Define scope carefully). For a process,
                              one needs to think of the product that will
                              result.
                            </strong>
                            <span>
                              <strong>
                                Name of the product / technology:{" "}
                              </strong>
                              {item.technology_name}
                            </span>
                            <span>
                              <strong>
                                Novelty / Innovation in the Product / Service?
                                (Device / new material / algorithm / new process
                                or any other):{" "}
                              </strong>
                              {item.technology_innovation}
                            </span>
                            <span>
                              <strong>
                                What does it do? (Use simple and non-technical
                                terms):{" "}
                              </strong>
                              {item.technology_work}
                            </span>
                            <span>
                              <strong>
                                What problem/pain does it solve?:{" "}
                              </strong>
                              {item.technology_solve}
                            </span>
                            <span>
                              <strong>
                                Explain USP (Unique Selling Proposition) of your
                                product? Please focus on “benefits” rather than
                                “features” of the technology to the end
                                customer:{" "}
                              </strong>
                              {item.technology_usp}
                            </span>
                            <span>
                              <strong>Your Target Market (Geography): </strong>
                              {item.technology_target}
                            </span>
                            <span>
                              <strong>
                                Who is the primary customer of your
                                product/service?:{" "}
                              </strong>
                              {item.technology_customer}
                            </span>
                            <span>
                              <strong>
                                Does your technology have any intellectual
                                property protection? If not, what is your plan
                                to protect your technology from competitors?:{" "}
                              </strong>
                              {item.technology_competitors}
                            </span>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              marginBottom: "0.3em",
                            }}
                          >
                            <strong>E. Additional information:</strong>
                            <span>
                              <strong>Pitch Deck: </strong>
                              {item.pitch_deck && (
                                <a href={item.pitch_deck} target="_blank">
                                  {item.pitch_deck} <MdOpenInNew />
                                </a>
                              )}
                            </span>
                            <span>
                              <strong>
                                Any other relevant information you would like us
                                to know?{" "}
                              </strong>
                              {item.more}
                            </span>
                            <span>
                              <strong>
                                How did you find out about our incubation
                                program?{" "}
                              </strong>
                              {item.how_found_us}
                            </span>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  ))}
                </>
              </Tab.Pane>
              <Tab.Pane eventKey="mentor">
                <>
                  {mentorList.map((item, index) => (
                    <Accordion className="mb-3">
                      <Accordion.Item eventKey={`mentor/${index}`}>
                        <Accordion.Header>
                          {index + 1}. {item.name} ({item.position},{" "}
                          {item.organization})
                        </Accordion.Header>
                        <Accordion.Body>
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <Row className="mb-3">
                              <Col md={4}>
                                <Button
                                  onClick={() =>
                                    handleExportPDF([item], "mentor")
                                  }
                                >
                                  Download Application (PDF)
                                </Button>
                              </Col>
                            </Row>
                            <span>
                              <strong>
                                <MdToday size={18} /> Application Date:{" "}
                              </strong>
                              {new Date(item.created_at).toLocaleDateString()}
                            </span>
                            <span>
                              <strong>Name: </strong>
                              {item.name}
                            </span>
                            <span>
                              <strong>Phone: </strong>
                              {item.phone}
                            </span>
                            <span>
                              <strong>Email: </strong>
                              {item.email}
                            </span>
                            <span>
                              <strong>LinkedIn Profile Url: </strong>
                              {item.linkedIn && (
                                <a href={item.linkedIn} target="_blank">
                                  {item.linkedIn}
                                  <MdOpenInNew size={18} />
                                </a>
                              )}
                            </span>
                            <span>
                              <strong>Position, Orgranization: </strong>
                              {item.position}, {item.organization}
                            </span>
                            <span>
                              <strong>Brief Profile: </strong>
                              {item.bio}
                            </span>
                            <span>
                              <strong>Experience (years): </strong>
                              {item.experience}
                            </span>
                            <span>
                              <strong>Domain of specialization: </strong>
                              {item.domain}
                            </span>
                            <span>
                              <strong>
                                Will you be able to spare 2-6 hours per week for
                                online mentoring session?:{" "}
                              </strong>
                              {item.available}
                            </span>
                            <span>
                              <strong>
                                Is there anything else you would like to share
                                with us?:{" "}
                              </strong>
                              {item.more}
                            </span>
                            <span>
                              <strong>CV: </strong>
                              {item.cv && (
                                <a href={item.cv} target="_blank">
                                  {item.cv}
                                  <MdOpenInNew size={18} />
                                </a>
                              )}
                            </span>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  ))}
                </>
              </Tab.Pane>
              <Tab.Pane eventKey="recruitment">
                {" "}
                <>
                  {recruitmentList.map((item, index) => (
                    <Accordion className="mb-3">
                      <Accordion.Item eventKey={`recruitment/${index}`}>
                        <Accordion.Header>
                          {index + 1}. ({item.position}) {item.first_name}{" "}
                          {item.middle_name} {item.last_name}
                        </Accordion.Header>
                        <Accordion.Body>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              marginBottom: "0.3em",
                            }}
                          >
                            <Row className="mb-3">
                              <Col md={4}>
                                <Button
                                  onClick={() =>
                                    handleExportPDF([item], "recruitment")
                                  }
                                >
                                  Download Application (PDF)
                                </Button>
                              </Col>
                            </Row>
                            <span>
                              <strong>
                                <MdToday size={18} /> Application Date:{" "}
                              </strong>
                              {new Date(item.created_at).toLocaleDateString()}
                            </span>
                            <span>
                              <strong>Position: </strong>
                              {item.position}
                            </span>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              marginBottom: "0.3em",
                            }}
                          >
                            <strong>A. Personal Information:</strong>
                            <span>
                              <strong>Name: </strong>
                              {item.first_name} {item.middle_name}{" "}
                              {item.last_name}
                            </span>
                            <span>
                              <strong>Gender: </strong>
                              {item.gender}
                            </span>
                            <span>
                              <strong>Date of Birth: </strong>
                              {new Date(item.dob).toLocaleDateString()}
                            </span>
                            <span>
                              <strong>Phone: </strong>
                              {item.phone}
                            </span>
                            <span>
                              <strong>Email: </strong>
                              {item.email}/ {item.email_alternate}
                            </span>
                            <span>
                              <strong>Category: </strong>
                              {item.category}
                            </span>
                            <span>
                              <strong>Marital Status: </strong>
                              {item.marital_status}
                            </span>
                            <span>
                              <strong>Nationality: </strong>
                              {item.nationality}
                            </span>
                            <span>
                              <strong>ID Proof: </strong>
                              {item.id_proof}({item.id_proof_number})
                            </span>
                            <span>
                              <strong>Are you Physically Handicapped?: </strong>
                              {item.physically_handicapped}
                            </span>
                            <span>
                              <strong>Are you Ex-servicemen?: </strong>
                              {item.ex_servicemen}
                            </span>
                            <span>
                              <strong>Current Address: </strong>
                              {item.current_address}
                              <br />
                              <b>City,District: </b>
                              {item.current_address_city},
                              {item.current_address_district}
                              <br />
                              <b> State, Country: </b>
                              {item.current_address_state},{" "}
                              {item.current_address_country}
                              <br />
                              <b>PinCode: </b>
                              {item.current_address_pincode}
                            </span>
                            <span>
                              <strong>Permanent Address: </strong>
                              {item.permanent_address}
                              <br />
                              <b>City,District: </b>
                              {item.permanent_address_city},
                              {item.permanent_address_district}
                              <br />
                              <b> State, Country: </b>
                              {item.permanent_address_state},{" "}
                              {item.permanent_address_country}
                              <br />
                              <b>PinCode: </b>
                              {item.permanent_address_pincode}
                            </span>
                            <span>
                              <strong>Birth Place: </strong>
                              {item.birth_city}, {item.birth_district},{" "}
                              {item.birth_state}
                            </span>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              marginBottom: "0.3em",
                            }}
                          >
                            <strong>B. Educational Details: </strong>
                            <Table bordered responsive size="lg">
                              <thead>
                                <tr>
                                  <th>S. No.</th>
                                  <th>Educational Level</th>
                                  <th>Degree/Examination</th>
                                  <th>Discilpine</th>
                                  <th>College/University</th>
                                  <th>
                                    Affiliated University/Board/Autonomus Body
                                  </th>
                                  <th>Passing Year</th>
                                  <th>Division/Class</th>
                                  <th>Percentage/GPA</th>
                                  <th>Average Marks/GPA</th>
                                  <th>Total Marks/GPA</th>
                                  <th>Rank, if any(In Board University)</th>
                                </tr>
                              </thead>
                              <tbody>
                                {JSON.parse(item?.education)?.map(
                                  (data, Dindex) => (
                                    <tr>
                                      <td>{Dindex + 1}.</td>
                                      <td>{data.educational_level}</td>
                                      <td>{data.degree}</td>
                                      <td>{data.discipline}</td>
                                      <td>{data.institute}</td>
                                      <td>{data.university}</td>
                                      <td>{data.year}</td>
                                      <td>{data.division}</td>
                                      <td>{data.grading}</td>
                                      <td>{data.average}</td>
                                      <td>{data.total}</td>
                                      <td>{data.rank}</td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </Table>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              marginBottom: "0.3em",
                            }}
                          >
                            <strong>C. Experience Details: </strong>
                            <Table bordered responsive size="lg">
                              <thead>
                                <tr>
                                  <th>S. No.</th>
                                  <th>Organization</th>
                                  <th>Designation</th>
                                  <th>From Date</th>
                                  <th>To Date</th>
                                  <th>Scale of Pay/Pay Scale Matrix</th>
                                  <th>Basic Pay/Pay in the Pay Scale Matrix</th>
                                  <th>Grade Pay</th>
                                  <th>Total Pay or Consolidated Amount</th>
                                  <th>
                                    Nature of Experience (limit to 200 words)
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {JSON.parse(item?.experience)?.map(
                                  (data, Dindex) => (
                                    <tr>
                                      <td>{Dindex + 1}.</td>
                                      <td>{data.organization}</td>
                                      <td>{data.designation}</td>
                                      <td>{data.from_date}</td>
                                      <td>{data.to_date}</td>
                                      <td>{data.pay_scale}</td>
                                      <td>{data.basic_pay}</td>
                                      <td>{data.grade_pay}</td>
                                      <td>{data.total_pay}</td>
                                      <td>{data.experience}</td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </Table>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              marginBottom: "0.3em",
                            }}
                          >
                            <strong>D. Additional Remarks: </strong>
                            <span>
                              <strong>
                                Special Qualification or Experience, including
                                the computer knowledge, which have not been
                                included above{" "}
                              </strong>
                              {item.additional_remarks}
                            </span>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              marginBottom: "0.3em",
                            }}
                          >
                            <strong>E. Upload Documents:</strong>
                            <span>
                              <strong>Photo: </strong>
                              <a href={item.photo} target="_blank">
                                {item.photo} <MdOpenInNew />
                              </a>
                            </span>
                            <span>
                              <strong>Signature: </strong>
                              <a href={item.signature} target="_blank">
                                {item.signature} <MdOpenInNew />
                              </a>
                            </span>
                            <span>
                              <strong>Age Proof: </strong>
                              <a href={item.age_proof} target="_blank">
                                {item.age_proof} <MdOpenInNew />
                              </a>
                            </span>
                            <span>
                              <strong>Id Proof: </strong>
                              <a href={item.id_proof_document} target="_blank">
                                {item.id_proof_document} <MdOpenInNew />
                              </a>
                            </span>
                            <span>
                              <strong>Education Certificate: </strong>
                              <a
                                href={item.educational_document}
                                target="_blank"
                              >
                                {item.educational_document} <MdOpenInNew />
                              </a>
                            </span>
                            <span>
                              <strong>Experience Certificate: </strong>
                              <a
                                href={item.experience_document}
                                target="_blank"
                              >
                                {item.experience_document} <MdOpenInNew />
                              </a>
                            </span>
                            {item.category_document && (
                              <span>
                                <strong>Category Certificate: </strong>
                                <a
                                  href={item.category_document}
                                  target="_blank"
                                >
                                  {item.category_document} <MdOpenInNew />
                                </a>
                              </span>
                            )}
                            <span>
                              <strong>Additional Document (if any): </strong>
                              {item.additional_document && (
                                <a
                                  href={item.additional_document}
                                  target="_blank"
                                >
                                  {item.additional_document} <MdOpenInNew />
                                </a>
                              )}
                            </span>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  ))}
                </>
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
      <Container className="p-3"></Container>
    </div>
  );
};

function getList(type, setList) {
  API.get(`/apply/${type}`)
    .then((res) => {
      if (res.data.status === "success") {
        setList(res.data.data);
      }
    })
    .catch((error) => {
      console.log(error);
    });
}

export default Applications;
