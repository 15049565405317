import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../App";
import Footer from "./Footer";
import Header from "./Header";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
// import Home from "../Components/Admin/AdminHome";
import Sidebar from "./Sidebar";
import Home from "../Pages/Home";
// import Circular from "../Components/Admin/Circular";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./Content.css";
import PublicNotice from "../Pages/PublicNotice";
import NewsLetterSubscription from "../Pages/NewsLetterSubscription";
import Carousel from "../Pages/Carousel";
import Content from "../Pages/Content";
import Member from "../Pages/Member";
import Portfolio from "../Pages/Portfolio";
import Stats from "../Pages/Stats";
import Testimonial from "../Pages/Testimonial";
import Event from "../Pages/Event";
import Startup from "../Pages/Startup";
import Mentor from "../Pages/Mentor";
import MentorAppointment from "../Pages/MentorAppointment";
import Query from "../Pages/Query";
import Applications from "../Pages/Applications";
import Updates from "../Pages/Updates";
import PastEvent from "../Pages/PastEvents";
import StartupRecruitment from "../Pages/StartupRecruitment";

const ContentRouter = () => {
  const navigate = useNavigate();
  const { currentUser } = useContext(UserContext);

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/login");
    }
  });
  return currentUser ? (
    <div className="content-div">
      <div className="content-header">
        <Header />
      </div>
      <div className="content-main">
        <div className="content-main-sidebar">
          <Sidebar />
        </div>
        <div className="content-main-body">
          <Routes>
            <Route path="/dashboard" element={<Home />} />
            <Route path="/notice" element={<PublicNotice />} />
            <Route
              path="/startup-recruitment"
              element={<StartupRecruitment />}
            />
            <Route path="/subscribe" element={<NewsLetterSubscription />} />
            <Route path="/carousel" element={<Carousel />} />
            <Route path="/content" element={<Content />} />
            <Route path="/member" element={<Member />} />
            <Route path="/portfolio" element={<Portfolio />} />
            <Route path="/stats" element={<Stats />} />
            <Route path="/testimonial" element={<Testimonial />} />
            <Route path="/events" element={<Event />} />
            <Route path="/past-events" element={<PastEvent />} />
            <Route path="/startup" element={<Startup />} />
            <Route path="/mentor" element={<Mentor />} />
            <Route path="/appointment" element={<MentorAppointment />} />
            <Route path="/query" element={<Query />} />
            <Route path="/application" element={<Applications />} />
            <Route path="/updates" element={<Updates />} />
          </Routes>
        </div>
      </div>
      <Footer />
    </div>
  ) : null;
};

export default ContentRouter;
