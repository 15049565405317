import React, { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { UserContext } from "../App";

const Home = (props) => {
  const { currentUser } = useContext(UserContext);
  const { state } = useLocation();
  console.log(currentUser);
  return currentUser ? (
    <div className="py-3">
      <h5>
        <center>Welcome, {currentUser.name}!</center>
      </h5>
    </div>
  ) : null;
};

export default Home;
