import React from "react";

const Footer = () => {
  return (
    <div>
      <footer className="footer">
        © 2023{" "}
        <b>
          Agri Business Incubation Foundation, Indian Institute of Technology,
          Kharagpur
        </b>
      </footer>
    </div>
  );
};

export default Footer;
