import React, { useContext } from "react";
import { hideShow } from "../utils/Helper";
import { UserContext } from "../App";
import API from "../API";
import { useNavigate } from "react-router-dom";
import { FaUser } from "react-icons/fa";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";

const Header = () => {
  const { currentUser } = useContext(UserContext);
  let navigate = useNavigate();
  return (
    <div className="">
      <Navbar
        className=""
        style={{ padding: "0.4em 1em", backgroundColor: "#dedede" }}
      >
        <Navbar.Brand href="/dashboard">
          <img
            className=""
            src="assets/images/logo.png"
            style={{ height: "2em" }}
            alt="logo"
          />
          {/* {" "}
          ABIF, IIT Kharagpur */}
        </Navbar.Brand>
      </Navbar>
      {/* <div className="topbar">
        <div className="topbar-left	d-none d-lg-block">
          <div className="text-center">
            <a href="#" className="logo">
              <img
                className="logo_abif"
                src="assets/images/logo.png"
                height={60}
                alt="logo"
              />
            </a>
          </div>
        </div>
        <nav className="navbar-custom">
          <ul className="list-inline menu-left mb-0">
            <li className="list-inline-item">
              <button
                type="button"
                className="button-menu-mobile open-left waves-effect"
                onClick={() => {
                  hideShow();
                }}
              >
                <i className="ion-navicon" />
              </button>
            </li>

            {currentUser ? (
              <li
                className="list-inline-item dropdown notification-list"
                style={{ float: "right" }}
              >
                <a style={{ color: "white" }}>{currentUser.role}</a>
                <a
                  className="nav-link dropdown-toggle arrow-none waves-effect nav-user"
                  data-toggle="dropdown"
                  href="#"
                  role="button"
                  aria-haspopup="false"
                  aria-expanded="false"
                >
                  <FaUser />
                </a>
                <div className="dropdown-menu dropdown-menu-right dropdown-menu-animated profile-dropdown ">
                  <a
                    className="dropdown-item"
                    href="#"
                    onClick={() => {
                      logout(currentUser, navigate);
                    }}
                  >
                    <i className="mdi mdi-logout m-r-5 text-muted" /> Logout
                  </a>
                </div>
              </li>
            ) : null}
          </ul>
          <div className="clearfix" />
        </nav>
      </div> */}
    </div>
  );
};

async function logout(currentUser, navigate) {
  await API.post("logout")
    .then((res) => {
      if (res.data.status === "success") {
        localStorage.clear();
        navigate("/login");
      }
    })
    .catch(function (error) {
      localStorage.clear();
      navigate("/login");
    });
}

export default Header;
