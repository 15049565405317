import React, { useEffect, useState, useContext, useRef } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import API from "../API";
import { UserContext, ShowContext } from "../App";
import { Button, Col, Container, Row, Form, Table } from "react-bootstrap";
import FormModal from "../Components/FormModal";
import {
  MdOutlineEditNote,
  MdDeleteForever,
  MdPlusOne,
  MdOpenInNew,
} from "react-icons/md";

let myInitialValues = {
  title: "",
  description: "",
  date: "",
  image: "",
  file_image: null,
};

const PastEvent = ({}) => {
  const { setShow, setMsg } = useContext(ShowContext);
  const refImage = useRef();
  const [open, setOpen] = useState(false);
  const [list, setList] = useState([]);
  const [data, setData] = useState(myInitialValues);
  const [edit, setEdit] = useState(false);

  useEffect(() => {
    getList(setList);
  }, []);
  return (
    <>
      <FormModal
        show={open}
        handleClose={() => {
          setData(myInitialValues);
          setOpen(false);
          setEdit(false);
          refImage.current.value = "";
        }}
        handleSubmit={async () => {
          if (!data.title) {
            alert("Incomplete Data");
          } else {
            if (edit && data.id) {
              editItem(data, list, setList, setShow, setMsg);
            } else {
              await addNew(data, list, setList, setShow, setMsg);
            }
            getList(setList);
            setData(myInitialValues);
            setOpen(false);
            setEdit(false);
            refImage.current.value = "";
          }
        }}
        heading={"Add New Past Event"}
      >
        <Form>
          <Row className="mb-3">
            <Form.Group as={Col}>
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="text"
                placeholder="Event Title"
                value={data.title}
                onChange={(e) => {
                  setData({ ...data, title: e.target.value });
                }}
              />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col}>
              <Form.Label>Description</Form.Label>
              <Form.Control
                type="text"
                placeholder="Event Description"
                value={data.description}
                onChange={(e) => {
                  setData({ ...data, description: e.target.value });
                }}
                as={"textarea"}
                rows={4}
              />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col}>
              <Form.Label>Date</Form.Label>
              <Form.Control
                type="datetime-local"
                value={data.date}
                onChange={(e) => {
                  setData({ ...data, date: e.target.value });
                }}
              />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} md={12}>
              <Form.Label>Images</Form.Label>
              <Form.Control
                type="file"
                ref={refImage}
                onChange={(e) => {
                  setData({ ...data, file_image: e.target.files[0] });
                }}
              />
            </Form.Group>
          </Row>
        </Form>
      </FormModal>
      <Container className="p-3">
        <Row className="mb-3">
          <Col md={9}>
            <h2>Past Events</h2>
          </Col>
          <Col md={3}>
            <Button style={{ width: "100%" }} onClick={() => setOpen(true)}>
              {" "}
              + Add New{" "}
            </Button>
          </Col>
        </Row>
        {list && list.length > 0 && (
          <Row>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Title</th>
                  <th>Description</th>
                  <th>Date</th>
                  <th>Images</th>
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {list.map((item, index) => (
                  <tr key={index + 1}>
                    <td>{index + 1}</td>
                    <td>{item.title}</td>
                    <td>{item.description}</td>
                    <td>{new Date(item.date).toLocaleString()}</td>
                    <td>
                      <img
                        src={item.image}
                        alt="Image"
                        style={{ maxWidth: 200 }}
                      />
                    </td>
                    <td>
                      <Form.Check
                        type="switch"
                        checked={item.status == 1}
                        onChange={(e) => {
                          updateStatus(
                            item.id,
                            e.target.checked ? 1 : 0,
                            list,
                            setList,
                            setShow,
                            setMsg
                          );
                        }}
                      />
                    </td>
                    <td>
                      <Row>
                        <Col>
                          <Button
                            className="action-btn"
                            onClick={() => {
                              setEdit(true);
                              setData({
                                ...item,
                                file_image: null,
                              });
                              setOpen(true);
                            }}
                          >
                            <MdOutlineEditNote />
                          </Button>
                          <Button
                            variant="danger"
                            className="action-btn"
                            onClick={() => {
                              deleteItem(
                                item.id,
                                list,
                                setList,
                                setShow,
                                setMsg
                              );
                            }}
                          >
                            <MdDeleteForever />
                          </Button>
                        </Col>
                      </Row>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Row>
        )}
      </Container>
    </>
  );
};

function getList(setList) {
  API.get("/events/past")
    .then((res) => {
      if (res.data.status === "success") {
        setList(res.data.data);
      }
    })
    .catch((error) => {
      console.log(error);
    });
}

async function addNew(values, list, setList, setShow, setMsg) {
  let fd = new FormData();
  fd.append("title", values.title);
  fd.append("description", values.description);
  fd.append("date", values.date);
  if (values.file_image) {
    fd.append("file_image", values.file_image);
  }
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  await API.post("events/past", fd, config)
    .then((res) => {
      if (res.data.status === "success") {
        setList([...list, res.data.data]);
        setShow(true);
        setMsg(res.data.message);
      }
    })
    .catch((error) => {
      console.log(error);
    });
}

async function editItem(values, list, setList, setShow, setMsg) {
  let fd = new FormData();
  fd.append("id", values.id);
  fd.append("title", values.title);
  fd.append("description", values.description);
  fd.append("date", values.date);
  fd.append("image", values.image);
  if (values.file_image) {
    fd.append("file_image", values.file_image);
  }
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  await API.post("events/past/update", fd, config)
    .then((res) => {
      if (res.data.status === "success") {
        setList(
          list.map((rec) => {
            if (rec.id !== values.id) {
              return rec;
            } else {
              return { ...rec, ...res.data.data };
            }
          })
        );
        setShow(true);
        setMsg(res.data.message);
      }
    })
    .catch((error) => {
      console.log(error);
    });
}

async function updateStatus(id, status, list, setList, setShow, setMsg) {
  await API.put("/events/past/status", { id: id, status: status })
    .then((res) => {
      if (res.data.status === "success") {
        setList(
          list.map((rec) => {
            if (rec.id !== id) {
              return rec;
            } else {
              return { ...rec, status: !rec.status };
            }
          })
        );
        setShow(true);
        setMsg(res.data.message);
      }
    })
    .catch((error) => {
      setShow(true);
      setMsg(error.response.data.message);
    });
}

async function deleteItem(id, list, setList, setShow, setMsg) {
  await API.delete("/events/past", { params: { id: id } })
    .then((res) => {
      if (res.data.status === "success") {
        setList(list.filter((rec) => rec.id !== id));
        setShow(true);
        setMsg(res.data.message);
      }
    })
    .catch((error) => {
      setShow(true);
      setMsg(error.response.data.message);
    });
}

export default PastEvent;
