import React, { useEffect, useState, useContext } from "react";
import API from "../API";
import { ShowContext } from "../App";
import { Col, Container, Row, Form, Table } from "react-bootstrap";

const MentorAppointment = () => {
  const { setShow, setMsg } = useContext(ShowContext);
  const [list, setList] = useState([]);

  useEffect(() => {
    getList(setList);
  }, []);
  return (
    <>
      <Container className="p-3">
        <Row className="mb-3">
          <Col md={9}>
            <h2>Mentor Appointments</h2>
          </Col>
          {/* <Col md={3}>
            <Button style={{ width: "100%" }} onClick={() => setOpen(true)}>
              {" "}
              + Add New{" "}
            </Button>
          </Col> */}
        </Row>
        {list && list.length > 0 && (
          <Row>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Mentor</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Phone</th>
                  <th>Message</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {list.map((item, index) => (
                  <tr key={index + 1}>
                    <td>{index + 1}</td>
                    <td>{item.mentor}</td>
                    <td>{item.name}</td>
                    <td>{item.email}</td>
                    <td>{item.phone}</td>
                    <td>{item.message}</td>
                    <td>
                      <Form.Check
                        type="switch"
                        checked={item.status == 1}
                        onChange={(e) => {
                          updateStatus(
                            item.id,
                            e.target.checked ? 1 : 0,
                            list,
                            setList,
                            setShow,
                            setMsg
                          );
                        }}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Row>
        )}
      </Container>
    </>
  );
};

async function updateStatus(id, status, list, setList, setShow, setMsg) {
  await API.put("/mentor/appointment/status", { id: id, status: status })
    .then((res) => {
      if (res.data.status === "success") {
        setList(
          list.map((rec) => {
            if (rec.id !== id) {
              return rec;
            } else {
              return { ...rec, status: !rec.status };
            }
          })
        );
        setShow(true);
        setMsg(res.data.message);
      }
    })
    .catch((error) => {
      setShow(true);
      setMsg(error.response.data.message);
    });
}

function getList(setList) {
  API.get("/mentor/appointment")
    .then((res) => {
      if (res.data.status === "success") {
        setList(res.data.data);
      }
    })
    .catch((error) => {
      console.log(error);
    });
}

export default MentorAppointment;
