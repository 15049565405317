import React, { useEffect, useState, useContext } from "react";
import API from "../API";
import { ShowContext } from "../App";
import { Button, Col, Container, Row, Form, Table } from "react-bootstrap";
import FormModal from "../Components/FormModal";
import { MdOutlineEditNote, MdDeleteForever } from "react-icons/md";

let myInitialValues = { title: "", number: "" };

const Stats = () => {
  const { setShow, setMsg } = useContext(ShowContext);
  const [open, setOpen] = useState(false);
  const [list, setList] = useState([]);
  const [data, setData] = useState(myInitialValues);
  const [edit, setEdit] = useState(false);

  useEffect(() => {
    getList(setList);
  }, []);
  return (
    <>
      <FormModal
        show={open}
        handleClose={() => {
          setData(myInitialValues);
          setOpen(false);
          setEdit(false);
        }}
        handleSubmit={async () => {
          if (!data.title) {
            alert("Incomplete Data");
          } else {
            if (edit && data.id) {
              editItem(data, list, setList, setShow, setMsg);
            } else {
              await addNew(data, list, setList, setShow, setMsg);
            }
            getList(setList);
            setData(myInitialValues);
            setOpen(false);
            setEdit(false);
          }
        }}
        heading={"Add New Stats"}
      >
        <Form>
          <Row className="mb-3">
            <Form.Group as={Col}>
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="text"
                placeholder="Title"
                value={data.title}
                onChange={(e) => {
                  setData({ ...data, title: e.target.value });
                }}
              />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col}>
              <Form.Label>Number</Form.Label>
              <Form.Control
                type="text"
                placeholder="Value"
                value={data.number}
                onChange={(e) => {
                  setData({ ...data, number: e.target.value });
                }}
              />
            </Form.Group>
          </Row>
        </Form>
      </FormModal>
      <Container className="p-3">
        <Row className="mb-3">
          <Col md={9}>
            <h2>Stats</h2>
          </Col>
          <Col md={3}>
            <Button style={{ width: "100%" }} onClick={() => setOpen(true)}>
              {" "}
              + Add New{" "}
            </Button>
          </Col>
        </Row>
        {list && list.length > 0 && (
          <Row>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Title</th>
                  <th>Number</th>
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {list.map((item, index) => (
                  <tr key={index + 1}>
                    <td>{index + 1}</td>
                    <td>{item.title}</td>
                    <td>{item.number}</td>
                    <td>
                      <Form.Check
                        type="switch"
                        checked={item.status == 1}
                        onChange={(e) => {
                          updateStatus(
                            item.id,
                            e.target.checked ? 1 : 0,
                            list,
                            setList,
                            setShow,
                            setMsg
                          );
                        }}
                      />
                    </td>
                    <td>
                      <Row>
                        <Col>
                          <Button
                            className="action-btn"
                            onClick={() => {
                              setEdit(true);
                              setData({ ...item });
                              setOpen(true);
                            }}
                          >
                            <MdOutlineEditNote />
                          </Button>
                          <Button
                            variant="danger"
                            className="action-btn"
                            onClick={() => {
                              deleteItem(
                                item.id,
                                list,
                                setList,
                                setShow,
                                setMsg
                              );
                            }}
                          >
                            <MdDeleteForever />
                          </Button>
                        </Col>
                      </Row>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Row>
        )}
      </Container>
    </>
  );
};

function getList(setList) {
  API.get("/stats")
    .then((res) => {
      if (res.data.status === "success") {
        setList(res.data.data);
      }
    })
    .catch((error) => {
      console.log(error);
    });
}

async function addNew(values, list, setList, setShow, setMsg) {
  await API.post("stats", values)
    .then((res) => {
      if (res.data.status === "success") {
        setList([...list, res.data.data]);
        setShow(true);
        setMsg(res.data.message);
      }
    })
    .catch((error) => {
      console.log(error);
    });
}

async function editItem(values, list, setList, setShow, setMsg) {
  await API.post("stats/update", values)
    .then((res) => {
      if (res.data.status === "success") {
        setList(
          list.map((rec) => {
            if (rec.id !== values.id) {
              return rec;
            } else {
              return { ...rec, ...res.data.data };
            }
          })
        );
        setShow(true);
        setMsg(res.data.message);
      }
    })
    .catch((error) => {
      console.log(error);
    });
}

async function updateStatus(id, status, list, setList, setShow, setMsg) {
  await API.put("/stats/status", { id: id, status: status })
    .then((res) => {
      if (res.data.status === "success") {
        setList(
          list.map((rec) => {
            if (rec.id !== id) {
              return rec;
            } else {
              return { ...rec, status: !rec.status };
            }
          })
        );
        setShow(true);
        setMsg(res.data.message);
      }
    })
    .catch((error) => {
      setShow(true);
      setMsg(error.response.data.message);
    });
}

async function deleteItem(id, list, setList, setShow, setMsg) {
  await API.delete("/stats", { params: { id: id } })
    .then((res) => {
      if (res.data.status === "success") {
        setList(list.filter((rec) => rec.id !== id));
        setShow(true);
        setMsg(res.data.message);
      }
    })
    .catch((error) => {
      setShow(true);
      setMsg(error.response.data.message);
    });
}

export default Stats;
