import React, { useEffect, useState, useContext, useRef } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import API from "../API";
import { UserContext, ShowContext } from "../App";
import { Button, Col, Container, Row, Form, Table } from "react-bootstrap";
import FormModal from "../Components/FormModal";
import { MdOutlineEditNote, MdDeleteForever } from "react-icons/md";

let myInitialValues = {
  position: "",
  type: "",
  name: "",
  image: "",
  info: "",
  file: null,
};

const Member = ({}) => {
  const { setShow, setMsg } = useContext(ShowContext);
  const ref = useRef();
  const [open, setOpen] = useState(false);
  const [list, setList] = useState([]);
  const [data, setData] = useState(myInitialValues);
  const [edit, setEdit] = useState(false);

  useEffect(() => {
    getList(setList);
  }, []);
  return (
    <>
      <FormModal
        show={open}
        handleClose={() => {
          setData(myInitialValues);
          setOpen(false);
          setEdit(false);
          ref.current.value = "";
        }}
        handleSubmit={async () => {
          if (!data.name) {
            alert("Incomplete Data");
          } else {
            if (edit && data.id) {
              editItem(data, list, setList, setShow, setMsg);
            } else {
              await addNew(data, list, setList, setShow, setMsg);
            }
            getList(setList);
            setData(myInitialValues);
            setOpen(false);
            setEdit(false);
            ref.current.value = "";
          }
        }}
        heading={"Add New Member"}
      >
        <Form>
          <Row className="mb-3">
            <Form.Group as={Col}>
              <Form.Label>Type</Form.Label>
              <Form.Select
                value={data.type}
                onChange={(e) => {
                  setData({ ...data, type: e.target.value });
                }}
              >
                <option>Select Type</option>
                <option value="BOARD-OF-DIRECTORS">Board of Directors</option>
                <option value="PMRC">PMRC</option>
                <option value="TEAM">Team Member</option>
              </Form.Select>
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col}>
              <Form.Label>Position</Form.Label>
              <Form.Control
                type="text"
                placeholder="Position"
                value={data.position}
                onChange={(e) => {
                  setData({ ...data, position: e.target.value });
                }}
              />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col}>
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Name"
                value={data.name}
                onChange={(e) => {
                  setData({ ...data, name: e.target.value });
                }}
              />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col}>
              <Form.Label>Info</Form.Label>
              <Form.Control
                type="text"
                placeholder="Member Info..."
                value={data.info}
                onChange={(e) => {
                  setData({ ...data, info: e.target.value });
                }}
                as={"textarea"}
                rows={4}
              />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col}>
              <Form.Label>Image</Form.Label>
              <Form.Control
                type="file"
                ref={ref}
                onChange={(e) => {
                  setData({ ...data, file: e.target.files[0] });
                }}
              />
            </Form.Group>
          </Row>
        </Form>
      </FormModal>
      <Container className="p-3">
        <Row className="mb-3">
          <Col md={9}>
            <h2>Member</h2>
          </Col>
          <Col md={3}>
            <Button style={{ width: "100%" }} onClick={() => setOpen(true)}>
              {" "}
              + Add New{" "}
            </Button>
          </Col>
        </Row>
        {list && list.length > 0 && (
          <Row>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Type</th>
                  <th>Position</th>
                  <th>Name</th>
                  <th>Info</th>
                  <th>Image</th>
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {list.map((item, index) => (
                  <tr key={index + 1}>
                    <td>{index + 1}</td>
                    <td>{item.type}</td>
                    <td>{item.position}</td>
                    <td>{item.name}</td>
                    <td>{item.info}</td>
                    <td>
                      {item.image && (
                        <img
                          src={item.image}
                          alt="Image"
                          style={{ maxWidth: 200 }}
                        />
                      )}
                    </td>
                    <td>
                      <Form.Check
                        type="switch"
                        checked={item.status == 1}
                        onChange={(e) => {
                          updateStatus(
                            item.id,
                            e.target.checked ? 1 : 0,
                            list,
                            setList,
                            setShow,
                            setMsg
                          );
                        }}
                      />
                    </td>
                    <td>
                      <Row>
                        <Col>
                          <Button
                            className="action-btn"
                            onClick={() => {
                              setEdit(true);
                              setData({ ...item, file: null });
                              setOpen(true);
                            }}
                          >
                            <MdOutlineEditNote />
                          </Button>
                          <Button
                            variant="danger"
                            className="action-btn"
                            onClick={() => {
                              deleteItem(
                                item.id,
                                list,
                                setList,
                                setShow,
                                setMsg
                              );
                            }}
                          >
                            <MdDeleteForever />
                          </Button>
                        </Col>
                      </Row>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Row>
        )}
      </Container>
    </>
  );
};

function getList(setList) {
  API.get("/member")
    .then((res) => {
      if (res.data.status === "success") {
        setList(res.data.data);
      }
    })
    .catch((error) => {
      console.log(error);
    });
}

async function addNew(values, list, setList, setShow, setMsg) {
  let fd = new FormData();
  fd.append("type", values.type);
  fd.append("position", values.position);
  fd.append("name", values.name);
  fd.append("info", values.info);
  if (values.file) {
    fd.append("file", values.file);
  }
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  await API.post("member", fd, config)
    .then((res) => {
      if (res.data.status === "success") {
        setList([...list, res.data.data]);
        setShow(true);
        setMsg(res.data.message);
      }
    })
    .catch((error) => {
      console.log(error);
    });
}

async function editItem(values, list, setList, setShow, setMsg) {
  console.log(values);
  let fd = new FormData();
  fd.append("id", values.id);
  fd.append("type", values.type);
  fd.append("position", values.position);
  fd.append("name", values.name);
  fd.append("info", values.info);
  if (values.file) {
    fd.append("file", values.file);
  }
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  await API.post("member/update", fd, config)
    .then((res) => {
      if (res.data.status === "success") {
        setList(
          list.map((rec) => {
            if (rec.id !== values.id) {
              return rec;
            } else {
              return { ...rec, ...res.data.data };
            }
          })
        );
        setShow(true);
        setMsg(res.data.message);
      }
    })
    .catch((error) => {
      console.log(error);
    });
}

async function updateStatus(id, status, list, setList, setShow, setMsg) {
  await API.put("/member/status", { id: id, status: status })
    .then((res) => {
      if (res.data.status === "success") {
        setList(
          list.map((rec) => {
            if (rec.id !== id) {
              return rec;
            } else {
              return { ...rec, status: !rec.status };
            }
          })
        );
        setShow(true);
        setMsg(res.data.message);
      }
    })
    .catch((error) => {
      setShow(true);
      setMsg(error.response.data.message);
    });
}

async function deleteItem(id, list, setList, setShow, setMsg) {
  await API.delete("/member", { params: { id: id } })
    .then((res) => {
      if (res.data.status === "success") {
        setList(list.filter((rec) => rec.id !== id));
        setShow(true);
        setMsg(res.data.message);
      }
    })
    .catch((error) => {
      setShow(true);
      setMsg(error.response.data.message);
    });
}

export default Member;
